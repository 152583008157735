<template>
    <v-card
        :loading="!(item && options)"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row v-if="item && options">
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.email"
                            :label="trans('fields.common.email')"
                            :error-messages="errors.email"
                            @input="clearError('label')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            :items="options.plans"
                            :label="trans('fields.common.plan')"
                            :multiple="false"
                            v-model="item.plan_id"
                            :error-messages="errors['plan_id']"
                            @input="clearError('plan_id')"
                        >
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                                        <v-list-item-subtitle v-html="options.planScopesKeyed[data.item.scope]"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            :items="options.planPeriods"
                            :label="trans('fields.common.planPeriod')"
                            :multiple="false"
                            v-model="item.plan_period"
                            :error-messages="errors['plan_period']"
                            @input="clearError('plan_period')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-dialog
                                ref="dialog"
                                v-model="planValidTillMenu"
                                :return-value.sync="item.plan_valid_till"
                                persistent
                                width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="item.plan_valid_till"
                                        :label="trans('fields.plan.valid_till')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                        @click:clear="item.plan_valid_till = null"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="item.plan_valid_till"
                                    scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="planValidTillMenu = false"
                                >
                                    {{ trans('buttons.common.cancel') }}
                                </v-btn>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(item.plan_valid_till)"
                                >
                                    {{ trans('buttons.common.save') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <v-select
                            :items="options.planPeriods"
                            :label="trans('fields.common.planPeriod')"
                            :multiple="false"
                            v-model="item.plan_period"
                            :error-messages="errors['plan_period']"
                            @input="clearError('plan_period')"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions v-if="item && options">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.close') }}</v-btn>
            <v-btn v-if="item.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";

    export default {
        name: "UserForm",
        mixins: [formValidate],
        props: {
            itemId: {
                type: [Number, String],
            },
        },
        data(){
            return {
                showRoute: 'admin.users.show',
                item: {
                    id: null,
                    email: '',
                    plan_id: null,
                    plan_valid_till: null,
                },
                error: null,
                options: null,
                planValidTillMenu: false,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                let id = this.itemId || this.item.id;
                if (id) {
                    axios.get(this.route(this.showRoute, id)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else {
                    this.loadOptions();
                }
            },

            loadOptions(){
                axios.post(this.route('admin.options'), {
                    plans: true,
                    planScopes: true,
                    planPeriods: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.users.store'), this.item).then((response) => {
                        console.log('save success');
                        this.item = response.data;
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.users.update', this.item.id), this.item).then(() => {
                        console.log('save success');
                        this.$emit('updated');
                    }).catch( (err) => {
                        let errors = {};
                        _.each(err.response.data.errors, (list, name) => {
                            _.set(errors, name, list);
                        });
                        this.errors = errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.item.id
                    ? this.trans('pages.user.newDialogTitle')
                    : this.trans('pages.user.editDialogTitle');
            },
        }
    }
</script>
